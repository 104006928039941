
import { defineComponent, ref, watch } from "vue";
import { useSearchKeywords } from "@/shared/composables/useSearchKeywords";
import { usePublicVideoIdeas } from "@/shared/composables/usePublicVideoIdeas";
import { useAuthentication } from "@/shared/composables/useAuthentication";
import PageLayout from "@/shared/components/Layouts/PageLayout.vue";
import VideoGrid from "@/shared/components/Videos/VideoGrid.vue";
import PageLayoutCardHeader from "@/shared/components/Layouts/PageLayoutCardHeader.vue";
import LoginBackground from "@/web/views/Login/LoginBackground.vue";
import { useI18n } from "vue-i18n";
import { useRedirectHelper } from "@/shared/composables/useRedirectHelper";
import { analyticsTrackVideoGalleryTerm } from "@/shared/utils/analytics";
import routeNames from "@/web/router/routeNames";

export default defineComponent({
  // For keep-alive
  name: routeNames.publicVideoGallery,
  setup() {
    const { t } = useI18n();
    const { redirectToVideoDetails } = useRedirectHelper();

    const selectedKeyword = ref("");

    const { isAuthenticated } = useAuthentication();
    const { searchKeywords, searchKeywordsLoading } = useSearchKeywords();
    const {
      publicVideoIdeas,
      publicVideoIdeasLoading,
      publicVideoIdeasFetchMore,
      publicVideoIdeasFetchMoreLoading,
      publicVideoIdeasHasNextPage,
    } = usePublicVideoIdeas(selectedKeyword);

    watch(searchKeywords, (searchKeywordsNewValue) => {
      // Pre-select first item only when no selected items yet
      if (!selectedKeyword.value) {
        selectedKeyword.value = searchKeywordsNewValue.at(0)?.keyword ?? "";
      }
    });

    const handleKeywordClick = (keyword: string) => {
      selectedKeyword.value = keyword;
      analyticsTrackVideoGalleryTerm(selectedKeyword.value);
    };

    const handleVideoClick = (id: string, title: string) => {
      redirectToVideoDetails({
        videoId: id,
        public: !isAuthenticated.value,
      });

      analyticsTrackVideoGalleryTerm(selectedKeyword.value, { id, title });
    };

    return {
      t,

      selectedKeyword,
      handleKeywordClick,
      handleVideoClick,

      searchKeywords,
      searchKeywordsLoading,
      publicVideoIdeas,
      publicVideoIdeasLoading,
      publicVideoIdeasFetchMore,
      publicVideoIdeasFetchMoreLoading,
      publicVideoIdeasHasNextPage,
    };
  },
  components: {
    PageLayout,
    VideoGrid,
    PageLayoutCardHeader,
    LoginBackground,
  },
});
