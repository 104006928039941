import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "video-gallery-page" }
const _hoisted_2 = { class: "mt-12" }
const _hoisted_3 = {
  key: 1,
  class: "flex justify-center gap-4 flex-wrap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_PageLayoutCardHeader = _resolveComponent("PageLayoutCardHeader")!
  const _component_LoginBackground = _resolveComponent("LoginBackground")!
  const _component_VideoGrid = _resolveComponent("VideoGrid")!
  const _component_PageLayout = _resolveComponent("PageLayout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PageLayoutCardHeader, {
      heading: _ctx.t(`Check our previous work`),
      subheading: 
        _ctx.t(
          `You need killer video content and video creative in order to grow your social media account and increase your reach.`
        )
      ,
      class: "relative"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.searchKeywordsLoading)
            ? (_openBlock(), _createBlock(_component_a_skeleton, { key: 0 }))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchKeywords, (keyword) => {
                  return (_openBlock(), _createBlock(_component_a_button, {
                    key: keyword,
                    type: 
              _ctx.selectedKeyword === keyword.keyword ? 'primary' : 'secondary'
            ,
                    onClick: ($event: any) => (_ctx.handleKeywordClick(keyword.keyword))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(keyword.keyword), 1)
                    ]),
                    _: 2
                  }, 1032, ["type", "onClick"]))
                }), 128))
              ]))
        ])
      ]),
      _: 1
    }, 8, ["heading", "subheading"]),
    _createVNode(_component_PageLayout, { class: "video-gallery-page__content pt-10 pb-32" }, {
      complexBackground: _withCtx(() => [
        _createVNode(_component_LoginBackground)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_VideoGrid, {
          "video-ideas": _ctx.publicVideoIdeas,
          loading: _ctx.publicVideoIdeasLoading,
          "load-more-loading": _ctx.publicVideoIdeasFetchMoreLoading,
          "has-next-page": _ctx.publicVideoIdeasHasNextPage,
          "result-card-props": {
          hideSubtitle: true,
        },
          onOnItemClick: _ctx.handleVideoClick,
          onOnLoadMore: _ctx.publicVideoIdeasFetchMore
        }, null, 8, ["video-ideas", "loading", "load-more-loading", "has-next-page", "onOnItemClick", "onOnLoadMore"])
      ]),
      _: 1
    })
  ]))
}